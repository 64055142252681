<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { categorieService } from '../../../../services/categorie.service';
import { albumService } from '../../../../services/album.service';
import { filesBaseUrl } from '../../../../constants/config';
import { customFunction } from '../../../../utils/customFunction';
import moment from 'moment';
import {
minValue,
    required,
} from "vuelidate/lib/validators";
import VsToast from '@vuesimple/vs-toast';
import { artisteService } from "../../../../services/artiste.service";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
    page: {
        title: "Albums",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, Multiselect, DatePicker },
    data() {
        return {
            albums: [],
            categories: [],
            artistes: [],
            items: [],
            filesBaseUrl,
            title: "Albums",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "libelle",
            sortDesc: false,
            fields: [
                { key: "image", label: 'Image', sortable: false },
                { key: "libelle", label: 'Libellé', sortable: true },
                { key: "amount", label: 'Montant', sortable: true },
                { key: "artiste", label: "Artiste", sortable: false },
                { key: "categorie", label: "Catégorie", sortable: false },
                { key: "activated", label: "Etat", sortable: false },
                { key: "options", sortable: false }
            ],
            form: {
                libelle: "",
                artiste: null,
                isActivated: true,
                amount: 0,
                categories: []
            },
            submitted: false,
            showForm: false,
            imageAlbum: null,
            isUpdate: false,
            updateId: null,
            datePublished: '',
            artiste: null
        };
    },
    validations: {
        form: {
            libelle: { required },
            amount: { required, minValue: minValue(150) }

        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.albums.length;
        }
    },
    mounted() {
        this.artiste = customFunction.destoreParam('yendiuartiste');
        this.getAlbums();
        this.getArtistes();
        this.getCategories();
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getAlbums() {
            this.albums = await albumService.get();
        },
        async getArtistes() {
            this.artistes = await artisteService.get();
        },
        async getCategories() {
            this.categories = await categorieService.get();
        },
        verifAdmin() {
            return customFunction.verifAdmin();
        },

        hideForm() {
            this.showForm = false;
            this.submitted = false;
            this.isUpdate = false;
            this.form.libelle = '';
            this.form.categories = [];
            this.form.artiste = [];
            this.datePublished = '';
            this.form.amount = 0;
            this.imageAlbum = null;
        },
        formateDateForm(d) {
            return moment(d).format('YYYY-MM-DD');
        },
        async showUpdate(item) {
            this.showForm = true;
            this.isUpdate = true;
            this.updateId = item.id;
            this.form.libelle = item.libelle;
            this.form.categories = item.categories;
            this.form.artiste = item.artistes;
            this.datePublished = new Date(item.published);
            this.imageAlbum = null;
            this.form.amount = item.amount;
        },
        async formSubmit() {
            this.submitted = true
            this.$v.$touch();
            if (!this.$v.$error) {

                if (this.imageAlbum == null && !this.isUpdate) {
                    VsToast.show({
                        title: 'Erreur champs',
                        message: 'Selectionner une image',
                        variant: 'warning',
                    })
                    return;
                }

                if (this.datePublished == '') {
                    VsToast.show({
                        title: 'Erreur champs',
                        message: 'Vérifier la date de publication',
                        variant: 'warning',
                    })
                    return;
                }

                let forms = new FormData();

                forms.append('libelle', this.form.libelle);
                forms.append('amount', this.form.amount);
                forms.append('published', this.formateDateForm(this.datePublished));
                this.form.categories.forEach(e => {
                    forms.append('categorie', e.id);
                })

                if (this.artiste == null) {
                    this.form.artiste.forEach(e => {
                        forms.append('artiste', e.id);
                    })
                } else {
                    forms.append('artiste', [this.artiste.id]);
                }

                forms.append('isActivated', this.artiste == null);

                if (this.imageAlbum != null) forms.append('image', this.imageAlbum.file);

                const rep = this.isUpdate ? await albumService.update(forms, this.updateId) : await albumService.create(forms);

                if (rep >= 400) {

                    VsToast.show({
                        title: 'Veuillez ressayer',
                        message: 'Une erreur s\'est produite',
                        variant: 'danger',
                    })

                } else {

                    VsToast.show({
                        title: this.isUpdate ? 'Mise à jour effectué' : 'Enregistrement effectué',
                        message: '',
                        variant: 'success',
                    });

                    this.getAlbums();
                    this.hideForm();
                }

            }
        },
        async desactivate(id) {
            const resp = await albumService.desactive(id);
            if (resp.status >= 400) {
                VsToast.show({
                    title: 'Une erreur s\'est produite! Veuillez réssayer plus tard',
                    message: '',
                    variant: 'error',
                });
            } else {
                VsToast.show({
                    title: 'Album désactivé de la plateforme',
                    message: '',
                    variant: 'success',
                });
                this.getAlbums();
            }
        },
        async activate(id) {
            const resp = await albumService.active(id);
            if (resp.status >= 400) {
                VsToast.show({
                    title: 'Une erreur s\'est produite! Veuillez réssayer plus tard',
                    message: '',
                    variant: 'error',
                });
            } else {
                VsToast.show({
                    title: 'Album disponible sur plateforme',
                    message: '',
                    variant: 'success',
                });
                this.getAlbums();
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div v-if="!showForm && (verifAdmin() || artiste != null )" class="col-md-8">
                <button @click="showForm = true" class="btn btn-primary">Ajouter</button>
            </div>
        </div>
        <form v-if="showForm" class="needs-validation mb-2" @submit.prevent="formSubmit">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Libellé</label>
                        <input id="validationCustom01" v-model="form.libelle" type="text" class="form-control"
                            placeholder="Libellé" value="" :class="{
                                'is-invalid': submitted && $v.form.libelle.$error,
                            }" />
                        <div v-if="submitted && $v.form.libelle.$error" class="invalid-feedback">
                            <span v-if="!$v.form.libelle.required">Champs requis.</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Montant</label>
                        <input id="validationCustom01" v-model="form.amount" type="number" class="form-control"
                            placeholder="Montant" value="" :class="{
                                'is-invalid': submitted && $v.form.amount.$error,
                            }" />
                        <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
                            <span v-if="!$v.form.amount.required">Champs requis.</span>
                            <span v-if="!$v.form.amount.minValue">La montant minimum est de 150F.</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 mb-2">
                        <label for="">Date de sortie</label>
                        <date-picker
                          v-model="datePublished"
                          format="DD/MM/YYYY"
                          placeholder="Selectionner la date"
                        ></date-picker>
                    </div>
                    <div v-if="artiste == null" class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="validationCustom01">Artiste</label>
                            <multiselect v-model="form.artiste" track-by="id" :options="artistes" label="nom_scene" :multiple="true"></multiselect>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="validationCustom01">Catégorie</label>
                            <multiselect v-model="form.categories" track-by="id" :options="categories" label="libelle" :multiple="true"></multiselect>
                        </div>
                    </div>
            </div>

            <div class="col-md-6 mb-2">
                <label for="">Image</label>
                <VueFileAgent v-model="imageAlbum" :multiple="false" :deletable="true"
                    :accept="'image/png, image/jpeg, image/jpg, image/wepp'" :maxSize="'2MB'" :maxFiles="1"
                    :helpText="'Sélectionner l\'image'" :errorText="{
                        type: 'Type de fichier invalid',
                        size: 'Votre fichier ne peux excéder 2Mo',
                    }">
                </VueFileAgent>
            </div>

            <button v-if="!isUpdate" class="btn btn-primary" type="submit">Enregistrer</button>
            <button v-if="isUpdate" class="btn btn-primary" type="submit">Mettre à jour</button>
            <button @click="hideForm()" class="btn btn-secondary" style="margin-left: 3px;" type="submit">Annuler</button>
        </form>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0 datatables">
                            <b-table :items="albums" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                                <template v-slot:cell(image)="r">
                                    <img :src="`${filesBaseUrl}/album/${r.item.image}`" height="50" alt="">
                                </template>
                                <template v-slot:cell(amount)="r">
                                    <span style="margin-right: 3px;" class="badge bg-primary">{{ r.item.amount | currencyFormat}}</span>
                                </template>
                                <template v-slot:cell(categorie)="r">
                                    <span style="margin-right: 3px;" v-bind:key="index" v-for="(item, index) of r.item.categories"
                                        class="badge bg-primary"
                                        > {{ item.libelle }}</span>
                                </template>
                                <template v-slot:cell(artiste)="r">
                                    <span style="margin-right: 3px;" v-bind:key="index" v-for="(item, index) of r.item.artistes"
                                        class="badge bg-info"
                                        > {{ item.nom_scene }}</span>
                                </template>
                                <template v-slot:cell(activated)="r">
                                    <span style="margin-right: 3px;"
                                        :class="r.item.activated ? 'badge bg-success' : 'badge bg-danger'"
                                        > {{ r.item.activated ? 'Validé' : 'Non validé' }}</span>
                                </template>
                                <template v-slot:cell(options)="r">
                                    <button v-if="!r.item.activated || verifAdmin()" @click="showUpdate(r.item)" title="Mettre à jour" class="btn btn-warning btn-sm" style="margin-right: 5px;"><i class="fa fa-edit"></i></button>
                                    <button v-if="r.item.activated && verifAdmin()" @click="desactivate(r.item.id)" title="Désactiver" class="btn btn-danger btn-sm"><i class="fa fa-lock"></i></button>
                                    <button v-if="!r.item.activated && verifAdmin()" @click="activate(r.item.id)" title="Activer" class="btn btn-success btn-sm"><i class="fa fa-unlock"></i></button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>